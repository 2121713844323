<template>
  <div class="box">
    <van-cell-group>
      <van-cell>
        <template #icon>
          <van-icon class="danger mr-5" name="gem" size="22"></van-icon>
        </template>
        <template #title>
          <span>账号余额:</span>
          <span class="danger fs120 ml-10">{{ user.userfen }}点</span>
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="快捷充值">
      <van-grid :column-num="3">
        <van-grid-item
          :class="selIndex == index ? 'checked' : ''"
          :key="index"
          @click="selIndex = index"
          class="item"
          v-for="(group, index) in groups"
        >
          <div class="group-name">{{ group.amount }} 元</div>
          <div>{{ group.coin }} 点</div>
        </van-grid-item>
      </van-grid>

      <van-cell value-class="tc danger fs120">获得{{ buyfen }}点</van-cell>
      <van-cell>
        <van-button @click="payReady" block round type="primary"
          >立即充值</van-button
        >
      </van-cell>
    </van-cell-group>
    <qr-code :src="qrcode" :oid="oid" :visible.sync="qrVisible" />
  </div>
</template>

<script>
import Vue from "vue";
import { Grid, GridItem, Dialog, Field } from "vant";
Vue.use(Grid).use(GridItem).use(Dialog).use(Field);

import { isWexin } from "@/libs/tools";
import QrCode from "@/pages/pay/wxpay.vue";

export default {
  name: "buyfen",
  components: {
    QrCode,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      selIndex: 0,
      qrVisible: false,
      qrcode: "",
      oid: "",
      groups: [],
      pr: 10,
      payid: 4, // 使用微信支付
      PayToFenMoney: "1",
      NumberKeyboardShow: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.user || {};
    },
    buyfen() {
      if (this.selIndex === 999) {
        if (!this.modi) {
          return 0;
        } else {
          return this.modi * this.pr;
        }
      } else if (this.groups && this.groups.length) {
        const item = this.groups[this.selIndex];
        return item.coin;
      } else {
        return 0;
      }
    },
    paymoney() {
      if (this.selIndex === 999) {
        if (!this.modi) {
          return 0;
        } else {
          return this.modi * 1;
        }
      } else if (this.groups && this.groups.length) {
        const item = this.groups[this.selIndex];
        return item.gmoney * 1;
      } else {
        return 0;
      }
    },
    specified() {
      if (!this.NumberKeyboardShow && !this.modi) {
        return "充值指定金额";
      } else {
        return (!this.modi ? "_" : this.modi) + " 元";
      }
    },
  },
  methods: {
    getList() {
      this.$http.get("user/recharge", { loading: true }).then((res) => {
        if (res.code === 1) {
          this.groups = res.data;
        }
      });
    },
    /**
     * 执行支付操作
     */
    payReady() {
      this.$store.commit("setLoading", true);
      const params = {
        pid: this.groups[this.selIndex].id,
        is_wechat: isWexin() ? 1 : 0,
        path: window.location.href,
      };
      this.$http.post("recharge_by_package", params).then((res) => {
        this.$store.commit("setLoading", false);
        if (res.url) {
          window.location.href = res.url;
        } else {
          this.qrcode = res.qrcode;
          this.oid = res.oid;
          this.qrVisible = true;
        }
      });
    },
    handleCustom() {
      this.selIndex = 999;
      this.modi = "";
      this.PayToFenMoney = 0;
      this.NumberKeyboardShow = true;
    },
    closeNumberKeyboard() {
      this.NumberKeyboardShow = false;
    },
    selectInput(e) {
      e.srcElement.select();
    },
  },
  activated() {
    this.getList();
  },
};
</script>

<style>
.box {
  width: 100%;
  max-width: 640px;
  height: 100%;
  background-color: #e3e3e3;
}
.item {
  text-align: center;
}
.item .group-name {
  font-size: 150%;
  line-height: 36px;
  font-weight: bold;
}
.item.checked .van-grid-item__content {
  background-color: #ed6a0c;
  color: #ebedf0;
}
</style>
